/**
 * Created by Raul on 03/05/2018.
 */

import {Injectable} from '@angular/core';
import {UsuarioResource} from './usuario.resource';
import {UsuarioModel} from './usuario.model';
import {RolModel} from '../../admin.roles.module/service/rol.model';
import {RolResource} from './rol.resource';
import {AreaModel} from '../../admin.areas.module/services/models/area.model';

@Injectable()
export class UsuarioService {

  constructor(private userResource: UsuarioResource,
              private authResource: RolResource) {
  }

  crearUsuario(user: UsuarioModel): Promise<any> {
    return this.userResource.crearUsuario(user);
  }

  actualizarUsuario(user: UsuarioModel): Promise<any> {
    return this.userResource.actualizarUsuario(user);
  }

  obtenerUsuarios(): Promise<UsuarioModel[]> {
    return this.userResource.obtenerUsuarios(); 
  }

  obtenerUsuariosPaginados(filter: any): Promise<UsuarioModel[]> {
    return this.userResource.obtenerUsuariosPaginacion(filter);
  }

  obtenerUsuario(id: string): Promise<UsuarioModel> {
    return this.userResource.obtenerUsuario({id: id});
  }

  obtenerRoles(): Promise<RolModel[]> {
    return this.authResource.obtenerRoles();
  }

  obtenerRol(id: string): Promise<RolModel> {
    return this.authResource.obtenerRol({id: id});
  }

  obtenerAreas(): Promise<AreaModel[]> {
    return this.userResource.obtenerAreas();
  }

  actualizarUsuariosDA(check: string): Promise<any> {
    return this.userResource.actualizarUsuariosDA({check: check});
  }

  obtenerUsuarioSesion(): Promise<UsuarioModel> {
    return this.userResource.usuarioSesion();
  }

  obtenerRolesMc() {
    return this.authResource.obtenerRolesMc();
  }
}
