/**
 * Created by Raul on 03/05/2018.
 */
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {RolModel} from '../../admin.roles.module/service/rol.model';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {Injectable} from '@angular/core';

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8001'
  pathPrefix: '/administracion'
})
// export class RolResource extends Resource {
export class RolResource extends ResourceAuth {
  // constructor(handler: ResourceHandler) {
  //  super(handler);
  // }

  constructor(handler: ResourceHandler, auth: AuthServerProvider) {
    super(handler, auth);
  }

  @ResourceAction({
    path: '/rol'
  })
  obtenerRoles: IResourceMethod<any, RolModel[]>;

  @ResourceAction({
    path: '/rol/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtenerRol: IResourceMethod<{ id: any }, RolModel>;

  @ResourceAction({
    path: '/rol',
    method: ResourceRequestMethod.Post
  })
  crearRol: IResourceMethod<RolModel, any>;

  @ResourceAction({
    path: '/rol/{!id}',
    method: ResourceRequestMethod.Patch
  })
  actualizarRol: IResourceMethod<RolModel, any>;

  @ResourceAction({
    path: '/roles-mc',
    method: ResourceRequestMethod.Get
  })
  obtenerRolesMc: IResourceMethod<any, {authority: string, id: number, user_id}[]>;
}
